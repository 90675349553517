<template>
    <div class="custommade">
        <div class="topbg">
            <img :src="topImg" alt="">
        </div>
        <div class="midbg">
            <div class="main">
                <left/>
                <div class="topstr">
                    <h2 v-if="navId == 13">软件定制</h2>
                    <h2 v-if="navId == 14">动漫设计</h2>
                </div>
                <div class="midmain1" v-if="navId == 13">
                    <div class="midmainbox" v-for="(item,index) in midMainBox" :key="index">
                        <div class="midmainhead">
                            <img :src="item.icon" alt="">
                            <span>{{item.title}}</span>
                        </div>
                        <div v-show="item.id == 1" class="item1">
                            <ul>
                                <li v-for="(item2,index2) in item.content" :key="index2">
                                    <img :src="item2.img" alt="">
                                    <h4>{{item2.title}}</h4>
                                    <div class="item1str">{{item2.str}}</div>
                                </li>
                            </ul>
                        </div>
                        <div v-show="item.id == 2" class="item2">
                            <img :src="item.img" alt="">
                        </div>
                        <div v-show="item.id == 3" class="item3">
                            <img :src="item.img" alt="">
                            <div class="item3str">
                                <p>{{item.str1}}</p><br>
                                <p>{{item.str2}}</p><br>
                                <p>{{item.str3}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="midmain2" v-if="navId == 14">
                    <div class="video">
                        <video  controls>
                            <source src="https://oss.eai9.com/pan/picture_album_0.mp4"  type="video/mp4">
                        </video>
                    </div>
                    <ul class="imglist">
                        <li v-for="(item,index) in imgList" :key="index">
                            <img :src="item" alt="">
                            <div class="playbg"><img :src="playImg" alt=""></div>
                        </li>
                    </ul>
                    <div class="midmain2bottom">
                        <img :src="midmain2bottom.img" alt="">
                        <span>{{midmain2bottom.title}}</span>
                        <div class="midmain2bottomstr">
                            <p>{{midmain2bottom.str1}}</p><br>
                            <p>{{midmain2bottom.str2}}</p><br>
                            <p>{{midmain2bottom.str3}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom"></div>
        <says/>
        <foots/>
        <hotline/>
    </div>
</template>

<script>
import left from '@/components/pc/leftnav'
import foots from '@/components/pc/foot'
import says from '@/components/pc/say'
import hotline from '@/components/pc/hotline.vue'
export default {
    components:{
        foots,
        left,
        says,
        hotline
    },
    data(){
        return{
            navId:13,
            topImg:require('@/assets/img/pcImg/aboutImg/12.png'),
            playImg:require('@/assets/img/pcImg/customMadeImg/playicon.png'),
            midMainBox:[
                {
                    id:1,
                    icon:require('@/assets/img/pcImg/customMadeImg/rjkf／icon／plan.png'),
                    title:'三阶段研发计划',
                    content:[
                        {
                            img:require('@/assets/img/pcImg/customMadeImg/a.png'),
                            title:'初期',
                            str:'实现功能实现基本功能，并试点运行。根据项目特性不断优化运作方式。',
                        },
                        {
                            img:require('@/assets/img/pcImg/customMadeImg/b.png'),
                            title:'中期',
                            str:'实现便捷与其他系统对接，实现各平台的集成化、一体化。',
                        },
                        {
                            img:require('@/assets/img/pcImg/customMadeImg/c.png'),
                            title:'后期',
                            str:'实现统计构建高度贴合需求、高效高能的数据统计平台，完成定制开发。',
                        },
                    ]
                },
                {
                    id:2,
                    icon:require('@/assets/img/pcImg/customMadeImg/rjkf／icon／flow.png'),
                    title:'软件设计流程',
                    img:require('@/assets/img/pcImg/customMadeImg/flow.png'),
                },
                {
                    id:3,
                    icon:require('@/assets/img/pcImg/customMadeImg/rjkf／icon／type.png'),
                    title:'软件原型设计',
                    img:require('@/assets/img/pcImg/customMadeImg/type.png'),
                    str1:'原型设计初期，我们会单独为客户分配一个域名，用于访问初步功能，收集各方面的意见建议，对功能进行修改。',
                    str2:'产品在设计过程当中，需要经常和用户、开发、测试就产品的各种问题进行沟通。在沟通的过程中，有辅助介质的沟通会有效的多，比如需要向用户说明设计方案的时候，有实质交付物的沟通就比只有口头的沟通来的顺畅，这里的实质交付物可以是文档、草图、原型，而原型则比前两者要好。',
                    str3:'原型因为效果接近模型，甚至可以做到和DEMO差不多，可以给用户一个非常直观的感受。具体到每个功能点，每个交互，都可以在原型上表现出来，这样就能大大减少沟通的时间，且借助原型的展示，开发出来的产品可以更加地符合需求。',
                },
            ],
            imgList:[
                require('@/assets/img/pcImg/customMadeImg/图像19.png'),
                require('@/assets/img/pcImg/customMadeImg/图像20.png'),
                require('@/assets/img/pcImg/customMadeImg/图像21.png'),
                require('@/assets/img/pcImg/customMadeImg/图像22.png'),
                require('@/assets/img/pcImg/customMadeImg/图像23.png'),
                require('@/assets/img/pcImg/customMadeImg/图像24.png'),
                require('@/assets/img/pcImg/customMadeImg/图像25.png'),
                require('@/assets/img/pcImg/customMadeImg/图像26.png'),
            ],
            midmain2bottom:{
                img:require('@/assets/img/pcImg/customMadeImg/icon／dm.png'),
                title:'动漫画及艺术设计服务',
                str1:'企业形象卡通设计旨在设计一个外在形象独特、特征鲜明、活泼有趣、让人怜爱的卡通人物来作为公司的象征，提升企业形象。',
                str2:'作为企业文化的载体，卡通的个性形象有利于彰显企业精神，增强企业内部凝聚力和战斗力，建立和谐的企业关系。企业卡通形象承担了沟通大众、宣传企业理念的重要任务，有利提高企业的社会知名度和美誉度；将卡通形象制作成系列宣传片，通过从概念、设计到实施的全方位整合服务，可以发挥虚拟形象在企业宣传中的特殊作用，拉近与目标对象的距离，增强企业品牌的生命力，促进深度传播，使企业品牌更具吸引力，更为人们喜爱。',
                str3:'我们以创意设计为核心，突出个性风格和品牌文化，以出众的技术品质，打造富有吸引力和传播力的视觉类影片。',
            }
        }
    },
    methods:{
    },
    watch:{
        $route:{
            handler(){
                this.navId = this.$route.params.id
            },
            immediate: true
        },
    },
    
}
</script>

<style lang="less" scoped>
.custommade{
    .topbg{
        width: 100%;
        position: relative;
        height: 460px;
        z-index: -5;
        background-image: repeating-linear-gradient(to right , rgb(31, 104, 240),  rgb(0, 79, 224));
        img{
            width: 100%;
            height: 460px;
            opacity: 0.2;
            z-index: -5;
        }
        
    }
    .midbg{
        width: 100%;
        // background-color: rgb(8, 59, 46);
        // clear: both;
        .main{
            width: 1200px;
            margin: -310px auto 0px;
            // background-color: gray;\
            // clear: both;
            .topstr{
                width: 793px;
                // height: 230px;
                // background-color: honeydew;
                margin: 80px 0 0 113px;
                float: left;
                // clear: both;
                h2{
                    font-size: 28px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .midmain1{
                width: 793px;
                // background-color: cadetblue;
                float: left;
                margin: 153px 0 0 113px;
                .midmainbox{
                    .midmainhead{
                        margin: 60px 0 40px 0;
                        img{
                            vertical-align: middle;
                            margin: -8px 10px 0 0;
                        }
                        span{
                            font-size: 18px;
                            font-weight: bold;
                            color: #31384E;
                        }
                    }
                    .item1{
                        ul{
                            li{
                                width: 200px;
                                // height: 250px;
                                display: inline-block;
                                margin-left: 60px;
                                // background-color: cornflowerblue;
                                h4{
                                    font-size: 16px;
                                    font-weight: bold;
                                    line-height: 30px;
                                    color: #207BFA;
                                    margin: 18px 0 5px 27px;
                                }
                                .item1str{
                                    width: 154px;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 19px;
                                    color: #697598;
                                    margin-left: 27px;
                                }
                            }
                        }
                    }
                    .item2{
                        img{
                            margin-left: 60px;
                        }
                    }
                    .item3{
                        img{
                            margin: 0 62px 0 60px;
                            float: left;
                        }
                        .item3str{
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19px;
                            color: #697598;
                            width: 416px;
                            height: 249px;
                            float: left;
                        }
                    }
                }
            }
            .midmain2{
                width: 800px;
                // height: 100px;
                // background-color: cadetblue;
                float: left;
                margin: 36px 0 0 113px;
                .video{
                    background: rgba(255, 255, 255, 0.15);
                    opacity: 1;
                    border-radius: 4px;
                    padding: 10px;
                    box-shadow: 0px 15px 43px rgba(0, 0, 0, 0.05);
                    video{
                    width: 100%;
                    height: 100%;
                                background-color: black;
                                border-radius: 4px;
                    }
                }
                .imglist{
                    // background-color: darkcyan;
                    margin: 41px 0 68px 0;
                    li{
                        background-color: darkkhaki;
                        display: inline-block;
                        width: 180px;
                        height: 120px;
                        margin: 0 20px 20px 0;
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 6px;
                        }
                        .playbg{
                            background: #000000;
                            opacity: 0.4;
                            border-radius: 6px;
                            width: 180px;
                            height: 120px;
                            float: left;
                            // position: absolute;
                            // z-index: 100;
                            // top: 0px;
                            // padding-top: 48px;
                            margin-top: -124px;
                            img{
                                width: 25px;
                                height: 25px;
                                margin: 48px 78px 0;
                                cursor:pointer;
                            }
                        }
                    }
                }
                .midmain2bottom{
                    // background-color: darkkhaki;
                    width: 800px;
                    // height: 100px;
                    img{
                        margin: -6px 10px 0 0;
                        vertical-align: middle;
                    }
                    span{
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 24px;
                        color: #31384E;
                    }
                    .midmain2bottomstr{
                        width: 750px;
                        margin: 40px 0 0 50px;
                        p{
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19px;
                            color: #697598;
                            text-indent: 2em;
                        }
                    }
                    
                }
            }
            
        }
        
    }
    .bottom{
        width: 100%;
        height: 60px;
        display: block;
        overflow:hidden;
        // background-color: burlywood;
    }
    /deep/ .foot{
    background-color: #fff;
}
}
</style>